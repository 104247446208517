<template>
    <v-simple-table style="border:1px solid #e0e0e0; width:380px;">
        <tbody>
            <tr>
                <td class="text-center blue--text">권장 사이즈 (300 x 320) 또는 동일 비율</td>
            </tr>
            <tr>
                <!-- 이미지 미리보기 -->
                <td class="ma-0 pa-0" style="background:white; text-align:center;">
                    <v-sheet class="d-flex justify-center pt-5 pb-6">
                        <v-img width="300" height="320" :src="url" />
                    </v-sheet>
                </td>
            </tr>
            <tr>
                <!-- file-input -->
                <td class="py-2 px-3">
                    <v-sheet
                        @drop.prevent="addDropFile"
                        @dragover.prevent
                    >
                        <v-file-input
                            placeholder="카드 이미지를 선택해주세요"
                            outlined
                            dense
                            show-size
                            hide-details
                            prepend-icon
                            color="blue"
                            v-model="uploadImage"
                            @change="onFileChange"
                        >
                            <template v-slot:prepend-inner>
                                <v-icon class="mr-1" color="blue">mdi-image-filter-hdr</v-icon>
                            </template>
                        </v-file-input>
                    </v-sheet>
                </td>
            </tr>
            <tr>
                <td class="py-2 px-3">
                    <v-btn
                        @click="$emit('close')"
                        depressed
                        dark
                        color="blue"
                        width="100%"
                    >
                        닫기
                    </v-btn>
                </td>
            </tr>
        </tbody>
    </v-simple-table>
</template>
<script>
export default {
    data: () => ({
        uploadImage: null,
        url: null,
    }),

    methods: {
        // 파일 선택시 이미지 표시
        onFileChange() {
            if(this.uploadImage == null){
                this.url = null
                this.$emit("imageUpdated", this.uploadImage)
            }else{
                const file = this.uploadImage
                this.$emit("imageUpdated", this.uploadImage)
                this.url = URL.createObjectURL(file)
            }
        },

        // 파일드롭
        addDropFile(e) { 
            const file = e.dataTransfer.files[0]
            this.uploadImage = e.dataTransfer.files[0]
            this.$emit("imageUpdated", this.uploadImage)
            this.url = URL.createObjectURL(file)
        },
    }
}
</script>