<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
            class="mt-2"
        >
            <!-- 검색 -->
            <v-sheet class="d-flex align-center justify-end mb-2 py-4 px-6 rounded-15" width="100%">
                <!-- 유형 -->
                <v-select
                    height="56"
                    style="width:250px;"
                    class="mr-3 rounded-15 font-weight-bold"
                    dense
                    outlined
                    hide-details
                    v-model="type"
                    :items="select_list.type"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-2">{{item}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- 순서 -->
                <v-select
                    height="56"
                    style="width:250px;"
                    class="mr-3 rounded-15 font-weight-bold"
                    dense
                    outlined
                    hide-details
                    v-model="sequence"
                    :items="select_list.sequence"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-2">{{item}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- 이름 검색 -->
                <v-text-field
                    height="56"
                    style="width:500px;"
                    class="rounded-15 font-weight-bold mr-3"
                    dense hide-details outlined
                    placeholder="이벤트명"
                    v-model="keyword"
                    @keyup.enter="search()"
                >
                    <template v-slot:append>
                        <v-icon size="32" class="pt-1 mr-1" color="#424361">mdi-magnify</v-icon>
                    </template>
                </v-text-field>
                <v-btn
                    class="rounded-10 font-weight-bold"
                    depressed
                    height="49"
                    width="86"
                    color="primary"
                    style="font-size:18px"
                    dark
                    @click="search()"
                >
                    검색
                </v-btn>
            </v-sheet>

            <!-- 이벤트 목록 -->
            <v-row
                dense
                class="d-flex pa-0"
            >
                <v-col
                    cols="3"
                    class="pl-1 pb-1"
                    v-for="item in currentPageList" :key="item.id"
                >
                    <v-sheet
                        style="cursor:pointer"
                        width="200"
                        class="px-3 py-4 rounded-15"
                        @click="goToRead(item)"
                    >
                        <v-sheet width="176">
                            <!-- 이미지 -->
                            <div style="margin-bottom:2px; width:176px; height:176px;">
                                <v-img width="176" height="176" :src="'/upload/pointmall/thumbnail/'+item.thumbnail"></v-img>
                            </div>

                            <!-- 날짜 & 상태 -->
                            <div class="d-flex align-center justify-space-between my-2">
                                <!-- 날짜 -->
                                <p class="my-0" style="font-size:10px; color:#23D2E2;">
                                    <span v-if="item.status != '무기한'">
                                        {{new Date(item.start_date).toLocaleDateString()}} ~ <br/>
                                        {{new Date(item.end_date).toLocaleDateString()}}
                                    </span>
                                </p>

                                <!-- 상태 -->
                                <v-sheet width="82" height="32" class="py-2 shadow rounded-15 d-flex align-center justify-center">
                                    <p class="my-0 font-weight-bold">
                                        <span v-if="item.status=='무기한'" style="font-size:13px;">{{item.status}}</span>
                                        <span v-else-if="item.status=='종료됨'" style="font-size:13px;">{{item.status}}</span>
                                        <span v-else-if="new Date(item.start_date) > new Date()" style="font-size:13px; color:#23D2E2;">D-{{Math.ceil((new Date(item.start_date) - new Date())/(24*3600*1000))}}</span>
                                        <span v-else-if="new Date(item.start_date) < new Date() && new Date(item.end_date) > new Date()" style="font-size:13px; color:#615DFA;">진행중</span>
                                        <span v-else-if="new Date(item.end_date) < new Date()" style="font-size:13px;">종료됨</span>
                                    </p>
                                </v-sheet>
                            </div>

                            <!-- 상품명 -->
                            <div class="mt-3 mb-2 font-weight-bold custom-truncate" style="height:30px;">
                                <p class="my-0" style="font-size:13px; line-height:15px !important;">{{item.title}}</p>
                            </div>

                            <!-- 판매방식 & 이벤트타입 & 카테고리 -->
                            <v-sheet class="d-flex justify-space-between my-1">
                                <!-- 판매방식 -->
                                <v-icon v-if="item.sell_type == '선착순'" size="12" style="margin-right:-6px;" color="#23D2E2">mdi-circle-outline</v-icon>
                                <v-icon v-if="item.sell_type == '당첨형'" size="12" style="margin-right:-6px;" color="#615DFA">mdi-circle-outline</v-icon>
                                <span style="font-size:12px;">{{item.sell_type}}</span>

                                <!-- 이벤트타입 -->
                                <v-icon v-if="item.event_type == '회원'" size="12" style="margin-right:-6px;" color="#615DFA">mdi-circle-outline</v-icon>
                                <v-icon v-if="item.event_type == 'AD'" size="12" style="margin-right:-6px;" color="#FF7A51">mdi-circle-outline</v-icon>
                                <v-icon v-if="item.event_type == '이벤트'" size="12" style="margin-right:-6px;" color="#23D2E2">mdi-circle-outline</v-icon>
                                <span style="font-size:12px;">{{item.event_type}}</span>

                                <!-- 카테고리 -->
                                <v-icon v-if="item.category == '신제품'" size="12" style="margin-right:-6px;" color="#23D2E2">mdi-circle-outline</v-icon>
                                <v-icon v-if="item.category == '중고품'" size="12" style="margin-right:-6px;" color="#1DA1F2">mdi-circle-outline</v-icon>
                                <span style="font-size:12px;">{{item.category}}</span>
                            </v-sheet>

                            <!-- 가격 & 남은 갯수 -->
                            <v-sheet class="d-flex justify-space-between px-2px">
                                <!-- 가격 -->
                                <p class="my-0 font-weight-bold" style="font-size:14px; color:#F8468D;">
                                    {{$toComma(item.price)}}P
                                </p>
                                
                                <!-- 남은 갯수 -->
                                <p class="my-0 font-weight-bold" style="font-size:14px;">
                                    {{item.amount - item.sold_amount}}개
                                </p>
                            </v-sheet>
                        </v-sheet>
                    </v-sheet>
                </v-col>
            </v-row>

            <!-- 페이지 -->
            <div class="d-flex justify-center align-center mx-2 mt-2">
                <!-- 공개유무 switch -->
                <div
                    style="width:120px;"
                >
                    <v-switch
                        v-if="$store.state.type=='관리자'"
                        v-model="is_switch"
                        inset
                        class="ma-0 mb-1"
                        hide-details
                        :label="is_switch? '활성':'비활성'"
                        @change="is_switch_update()"
                    ></v-switch>
                </div>

                <v-spacer></v-spacer>
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    :total-visible="5"
                ></v-pagination>
                <v-spacer></v-spacer>
                <v-btn 
                    v-if="$store.state.type=='관리자'"
                    width="120"
                    height="35"
                    dark color="#23D2E2" depressed 
                    class="font-weight-bold rounded-10"
                    @click="$router.push('/pointmall/write')"
                >
                    <v-icon size="20" left>mdi-pencil</v-icon>
                    상품 등록
                </v-btn>
            </div>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
            class="mt-2"
        >
            <!-- 검색 -->
            <div class="d-flex align-center justify-end mb-2 px-4 rounded-15">
                <!-- 순서 -->
                <v-select
                    height="40"
                    style="width:250px; background:white;"
                    class="mr-2 rounded-10 font-weight-bold"
                    dense
                    outlined
                    hide-details
                    v-model="sequence"
                    :items="select_list.sequence"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-2">{{item}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <v-btn
                    class="rounded-10 font-weight-bold"
                    depressed
                    height="40"
                    width="104"
                    color="primary"
                    style="font-size:12px"
                    dark
                    @click="switchDetail=!switchDetail"
                >
                    <v-icon small class="mr-1">mdi-magnify</v-icon>
                    상세검색
                </v-btn>
            </div>

            <!-- 상세조건 (Mobile) -->
            <v-sheet
                v-if="$vuetify.breakpoint.mobile && switchDetail"
                class="pa-2"
                width="100%"
                style="position:absolute; top:0px;"
            >
                <v-sheet
                    class="pt-8 px-3 mobile"
                    style="border:2px solid #f8468d;"
                >
                    <v-icon style="position:absolute; top:12px; right:12px;" size="20" color="#F8468D" @click="switchDetail = false">mdi-close</v-icon>
                    <!-- 키워드 검색 -->
                    <v-sheet
                        class="rounded-10 pa-5"
                    >
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-2">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold;">포인트몰 검색</span>

                        <div class="d-flex mt-3 mb-2">
                            <!-- type -->
                            <v-select
                                height="40"
                                style="width:50%;"
                                class="rounded-10"
                                label="검색항목"
                                dense
                                outlined
                                hide-details
                                placeholder="전체"
                                persistent-placeholder
                                :items="select_list.type"
                                v-model="keyword.type"
                                :menu-props="{ offsetY: true }"
                            >
                                <template v-slot:selection="{ item }">
                                    <span class="pl-3 pt-1">{{item}}</span>
                                </template>
                                <template v-slot:append>
                                    <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                                </template>
                            </v-select>
                        </div>
                        <div class="d-flex">
                            <!-- TEXT -->
                            <v-text-field
                                height="40"
                                class="rounded-10"
                                outlined
                                hide-details
                                dense
                                placeholder="검색어를 입력하세요"
                                persistent-placeholder
                                v-model="keyword.text"
                                append-icon="mdi-magnify"
                                @keyup.enter="submit(false)"
                            >
                                <template v-slot:append>
                                    <v-icon size="20" color="#424361">mdi-magnify</v-icon>
                                </template>
                            </v-text-field>
                        </div>
                    </v-sheet>

                    <div
                        class="d-flex justify-center mt-3 mb-3"
                    >
                        <!-- 검색 버튼 -->
                        <v-btn
                            depressed
                            dark
                            x-small
                            class="mr-2 rounded-10"
                            style="font-size:13px; font-weight:bold"
                            color="primary"
                            width="78"
                            height="34"
                            @click="submit(false); switchDetail = false;"
                        >
                            검색
                        </v-btn>

                        <!-- 초기화 -->
                        <v-btn
                            depressed
                            dark
                            x-small
                            class="rounded-10"
                            style="font-size:13px; font-weight:bold"
                            color="#F8468D"
                            width="78"
                            height="34"
                            @click="keywordReset()"
                        >
                            초기화
                        </v-btn>
                    </div>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-row
                class="d-flex pa-0 mx-3"
                dense
            >
                <v-col
                    cols="6"
                    class="pl-1 pb-2"
                    v-for="item in currentPageList" :key="item.id"
                >
                    <v-sheet
                        style="cursor:pointer"
                        class="px-3 py-4 rounded-15 mx-auto"
                        @click="goToRead(item)"
                    >
                        <v-sheet min-width="136">
                            <!-- 이미지 -->
                            <div style="margin-bottom:2px; width:132px; height:132px;">
                                <v-img width="132" height="132" :src="'/upload/pointmall/thumbnail/'+item.thumbnail"></v-img>
                            </div>

                            <!-- 날짜 & 상태 -->
                            <div class="d-flex align-center justify-space-between my-2">
                                <!-- 날짜 -->
                                <p class="my-0" style="font-size:9px; color:#23D2E2; line-height:11px;">
                                    <span v-if="item.status != '무기한'">
                                        {{new Date(item.start_date).toLocaleDateString()}} ~<br/>
                                        {{new Date(item.end_date).toLocaleDateString()}}
                                    </span>
                                </p>

                                <!-- 상태 -->
                                <v-sheet width="64" height="24" class="py-2 shadow rounded-15 d-flex align-center justify-center">
                                    <p class="my-0 font-weight-bold pb-1">
                                        <span v-if="item.status=='무기한'" style="font-size:10px;">{{item.status}}</span>
                                        <span v-else-if="item.status=='종료됨'" style="font-size:10px;">{{item.status}}</span>
                                        <span v-else-if="new Date(item.start_date) > new Date()" style="font-size:10px; color:#23D2E2;">D-{{Math.ceil((new Date(item.start_date) - new Date())/(24*3600*1000))}}</span>
                                        <span v-else-if="new Date(item.start_date) < new Date() && new Date(item.end_date) > new Date()" style="font-size:10px; color:#615DFA;">진행중</span>
                                        <span v-else-if="new Date(item.end_date) < new Date()" style="font-size:10px;">종료됨</span>
                                    </p>
                                </v-sheet>
                            </div>

                            <!-- 상품명 -->
                            <div class="mt-3 mb-2 font-weight-bold custom-truncate" style="height:30px;">
                                <p class="my-0" style="font-size:13px; line-height:15px !important;">{{item.title}}</p>
                            </div>

                            <!-- 판매방식 & 이벤트타입 & 카테고리 -->
                            <v-sheet class="d-flex justify-space-between mt-1 mb-6px">
                                <!-- 판매방식 -->
                                <v-icon v-if="item.sell_type == '선착순'" size="10" style="margin-right:-6px;" color="#23D2E2">mdi-circle</v-icon>
                                <v-icon v-if="item.sell_type == '당첨형'" size="10" style="margin-right:-6px;" color="#615DFA">mdi-circle</v-icon>
                                <span style="font-size:8px;">{{item.sell_type}}</span>

                                <!-- 이벤트타입 -->
                                <v-icon v-if="item.event_type == '회원'" size="10" style="margin-right:-6px;" color="#615DFA">mdi-circle</v-icon>
                                <v-icon v-if="item.event_type == 'AD'" size="10" style="margin-right:-6px;" color="#FF7A51">mdi-circle</v-icon>
                                <v-icon v-if="item.event_type == '이벤트'" size="10" style="margin-right:-6px;" color="#23D2E2">mdi-circle</v-icon>
                                <span style="font-size:8px;">{{item.event_type}}</span>

                                <!-- 카테고리 -->
                                <v-icon v-if="item.category == '신제품'" size="10" style="margin-right:-6px;" color="#23D2E2">mdi-circle</v-icon>
                                <v-icon v-if="item.category == '중고품'" size="10" style="margin-right:-6px;" color="#1DA1F2">mdi-circle</v-icon>
                                <span style="font-size:8px;">{{item.category}}</span>
                            </v-sheet>

                            <!-- 가격 & 남은 갯수 -->
                            <div class="d-flex justify-space-between px-2px">
                                <!-- 가격 -->
                                <p class="my-0 font-weight-bold" style="font-size:10px; color:#F8468D;">
                                    {{$toComma(item.price)}}P
                                </p>
                                
                                <!-- 남은 갯수 -->
                                <p class="my-0 font-weight-bold" style="font-size:11px;">
                                    {{item.amount - item.sold_amount}}개
                                </p>
                            </div>
                        </v-sheet>
                    </v-sheet>
                </v-col>
            </v-row>

            <!-- 페이지 -->
            <div class="d-flex justify-center align-center mx-3 mt-2 mb-4">
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    :total-visible="5"
                ></v-pagination>
            </div>

            <!-- 메뉴 -->
            <div class="d-flex justify-center mb-2">
                <!-- 포인트 획득 가이드 -->
                <v-btn
                    width="124"
                    height="34"
                    class="rounded-10 px-0 mr-2"
                    color="#615DFA"
                    dark
                    depressed
                    v-ripple="false"
                    style="font-size:12px; letter-spacing:-0.5px; min-width:0px;"
                    @click="$router.push('/policy/pointguide?type=read')"
                >
                    포인트 획득 가이드
                </v-btn>

                <!-- 상품 협찬하기 -->
                <v-btn
                    width="124"
                    height="34"
                    class="rounded-10 px-0"
                    color="#615DFA"
                    dark
                    depressed
                    v-ripple="false"
                    style="font-size:12px; letter-spacing:-0.5px; min-width:0px;"
                    @click="$router.push('/board/list?board_group=포인트몰&board_name=상품%20협찬하기')"
                >
                    상품 협찬하기
                </v-btn>
            </div>

            <!-- 작성하기 -->
            <v-btn
                v-if="$store.state.type == '관리자'"
                width="34"
                height="34"
                class="rounded-10 px-0 d-flex mx-auto mb-16"
                color="primary"
                dark
                depressed
                v-ripple="false"
                style="font-size:12px; letter-spacing:-0.5px; min-width:0px;"
                to="/pointmall/write"
            >
                <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        select_list: {
            type: [
                "전체",
                "진행중",
                "종료됨",
                "선착순",
                "당첨형",
                "신제품",
                "중고품"
            ],

            sequence: [
                "최신순",
                "오래된순",
                "가격 높은순",
                "가격 낮은순",
                "수량 많은순",
                "수량 적은순"
            ]
        },

        switchDetail: false,

        // 검색 키워드
        keyword:  "",

        // 순서
        type: "전체",
        sequence: "최신순",

        // 페이징
        list: [],
        currentPageList: [],
        page: 1,
        pageCount: 1,

        is_switch: 1,
    }),

    mounted() {
        // 전체 활성/비활성
        this.$http.post('/api/admin_function/select/pointmall_switch')
        .then((res) => {
            // console.log(res)
            if(res.data[0].pointmall_switch == 0){
                this.is_switch = 0
            }else{
                this.is_switch = 1
            }
        })

        this.search()
    },

    watch: {
        page(newVal) {
            this.$router.replace({ query: { ...this.$route.query, page: newVal } })
            this.currentPageList = this.list.slice((this.page-1)*16,(this.page)*16)
            window.scrollTo(0, 0)
        },

        type() {
            this.search()
        },

        sequence() {
            this.search()
        }
    },

    methods: {
        // 검색
        search(){
            this.$http.post('/api/pointmall/select/search', {
                params: {
                    keyword: this.keyword
                }
            }).then((res => {
                // 유형
                if(this.type == "전체"){
                    this.list = res.data
                }
                else if(this.type == "진행중"){
                    this.list = res.data.filter(e => e.status == "무기한" || (e.status == "진행중" && (new Date(e.start_date) < new Date() && new Date(e.end_date) > new Date())))
                }
                else if(this.type == "종료됨"){
                    this.list = res.data.filter(e => e.status == "종료됨"|| (e.status == "진행중" && new Date(e.end_date) < new Date()))
                }
                else if(this.type == "선착순"){
                    this.list = res.data.filter(e => e.sell_type == "선착순")
                }
                else if(this.type == "당첨형"){
                    this.list = res.data.filter(e => e.sell_type == "당첨형")
                }
                else if(this.type == "신제품"){
                    this.list = res.data.filter(e => e.category == "신제품")
                }
                else if(this.type == "중고품"){
                    this.list = res.data.filter(e => e.category == "중고품")
                }

                // 순서
                if(this.sequence == "최신순"){
                    this.list = this.list.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
                }
                else if(this.sequence == "오래된순"){
                    this.list = this.list.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())
                }
                else if(this.sequence == "가격 높은순"){
                    this.list = this.list.sort((a, b) => b.price - a.price)
                }
                else if(this.sequence == "가격 낮은순"){
                    this.list = this.list.sort((a, b) => a.price - b.price)
                }
                else if(this.sequence == "수량 많은순"){
                    this.list = this.list.sort((a, b) => (b.amount - b.sold_amount) - (a.amount - a.sold_amount))
                }
                else if(this.sequence == "수량 적은순"){
                    this.list = this.list.sort((a, b) => (a.amount - a.sold_amount) - (b.amount - b.sold_amount))
                }

                // 페이징
                if (this.$route.query.page) {
                    this.page = parseInt(this.$route.query.page)
                }
                this.currentPageList = this.list.slice((this.page-1)*16,(this.page)*16)
                this.pageCount = Math.ceil(this.list.length/16)
            }))
        },

        // 상품상세로 이동
        goToRead(item){
            this.$router.push('/pointmall/read?id='+item.id)
        },

        is_switch_update(){
            this.$http.post("/api/admin_function/update/pointmall_switch", {
                params: {
                    pointmall_switch: this.is_switch
                }
            })
        }
    }
}
</script>
<style scoped>
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 12px;
}

::v-deep .v-select input {
    margin-left:8px;
    font-size: 12px;
}

/* 목록 > 상태 > 그림자 */
.shadow{
    box-shadow: 0px 0px 6px 3px #F1F2F6 !important;
}

/* Custom Truncate */
.custom-truncate{
    display: -webkit-box;
    max-width: 180px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>
<style scoped>
/* 페이징 */
::v-deep .v-pagination__item {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .v-pagination__item--active {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .v-pagination__navigation {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .v-pagination__navigation .v-icon{
    color:white;
}
</style>
<style scoped>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}
</style>