<template>
    <v-sheet color="transparent">
        <!-- 헤더 -->
        <PointHeader />
        <!-- 상품 등록 -->
        <v-card class="pa-4 rounded-10 mt-2" flat>
            <!-- 썸네일 & 제목 -->
            <v-sheet class="d-flex">
                <v-sheet>
                    <v-btn
                        height="48"
                        max-width="210"
                        dark
                        color="blue lighten-1"
                        class="rounded-10"
                        outlined
                        @click="dialog.thumbnail=true"
                    >
                        상품 썸네일 등록하기
                    </v-btn>
                    <v-dialog v-model="dialog.thumbnail" width="auto">
                        <Thumbnail 
                            @imageUpdated="imageImport"
                            @close="dialog.thumbnail = false"
                        />
                    </v-dialog>
                </v-sheet>
                <v-text-field
                    height="48"
                    class="rounded-10 ml-2"
                    hide-details
                    dense
                    outlined
                    placeholder="상품명을 입력해주세요"
                    solo
                    flat
                    v-model="product.title"
                ></v-text-field>
            </v-sheet>

            <!-- 본문 (상품 설명) -->
            <v-sheet class="mt-2">
                <TipTapWriterMobile
                    v-if="$vuetify.breakpoint.mobile"
                    class="tiptap_style"
                    :options="options"
                    @emit_count="import_content_count"
                />
                <TipTapWriter
                    v-if="!$vuetify.breakpoint.mobile"
                    class="tiptap_style"
                    :options="options"
                    @emit_count="import_content_count"
                />
            </v-sheet>

            <!-- 추첨방식 & 가격 & 배송비 (PC) -->
            <v-sheet
                v-if="!$vuetify.breakpoint.mobile"
                class="d-flex mt-2"
            >
                <v-select
                    class="rounded-10 mr-2"
                    style="width:211px;"
                    height="48"
                    hide-details
                    dense
                    outlined
                    placeholder="상품 추첨방식"
                    :items="select_list.sell_type"
                    v-model="product.sell_type"
                >
                    <template v-slot:append>
                        <v-icon class="pt-2px mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <v-spacer></v-spacer>
                <v-sheet outlined class="pa-1 mr-2 rounded-10">
                    <v-sheet class="d-flex align-center" width="275">
                        <v-sheet width="120" class="text-center grey--text mr-4">상품가격</v-sheet>
                        <v-text-field
                            hide-details dense solo flat
                            placeholder="상품 가격"
                            suffix="P"
                            v-model="product.price"
                            @input="e => product.price = $inputToComma(e)"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>
                <v-sheet outlined class="pa-1 rounded-10">
                    <v-sheet class="d-flex align-center" width="275">
                        <v-sheet width="120" class="text-center grey--text mr-4">배송비</v-sheet>
                        <v-text-field
                            hide-details dense solo flat
                            placeholder="배송비"
                            suffix="P"
                            v-model="product.delivery_price"
                            @input="e => product.delivery_price = $inputToComma(e)"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>
            </v-sheet>

            <!-- 추첨방식 & 가격 & 배송비 (Mobile) -->
            <v-sheet
                v-if="$vuetify.breakpoint.mobile"
                class="mt-2"
            >
                <v-select
                    class="rounded-10"
                    height="48"
                    hide-details
                    dense
                    outlined
                    placeholder="상품 추첨방식"
                    :items="select_list.sell_type"
                    v-model="product.sell_type"
                >
                    <template v-slot:append>
                        <v-icon class="pt-2px mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>
                
                <v-sheet outlined class="pa-1 rounded-10 mt-2">
                    <v-sheet class="d-flex align-center">
                        <v-sheet width="120" class="grey--text ml-4">상품가격</v-sheet>
                        <v-text-field
                            hide-details dense solo flat
                            placeholder="상품 가격"
                            reverse
                            prefix="P"
                            v-model="product.price"
                            @input="e => product.price = $inputToComma(e)"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>

                <v-sheet outlined class="pa-1 rounded-10 mt-2">
                    <v-sheet class="d-flex align-center">
                        <v-sheet width="120" class="grey--text ml-4">배송비</v-sheet>
                        <v-text-field
                            hide-details dense solo flat
                            placeholder="배송비"
                            reverse
                            prefix="P"
                            v-model="product.delivery_price"
                            @input="e => product.delivery_price = $inputToComma(e)"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>
            </v-sheet>

            <!-- 카테고리 & 이벤트 기간 (PC) -->
            <v-sheet class="d-flex mt-2" v-if="!$vuetify.breakpoint.mobile">
                <v-select
                    class="rounded-10 mr-2"
                    style="width:211px;"
                    height="48"
                    hide-details
                    dense
                    outlined
                    placeholder="상품 카테고리"
                    :items="select_list.category"
                    v-model="product.category"
                >
                    <template v-slot:append>
                        <v-icon class="pt-2px mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <v-spacer></v-spacer>
                <!-- 기간 있는 이벤트 -->
                <v-sheet outlined class="pa-1 rounded-10" v-if="this.product.status=='진행중'">
                    <v-sheet class="d-flex align-center" width="568">
                        <v-text-field
                            hide-details dense solo flat
                            type="datetime-local"
                            class="text-body-2"
                            v-model="product.start_date"
                            @change="start_date()"
                        ></v-text-field>
                        <p class="my-0 mx-2 font-weight-bold">~</p>
                        <v-text-field
                            hide-details dense solo flat
                            type="datetime-local"
                            class="text-body-2"
                            v-model="product.end_date"
                            @change="end_date()"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>

                <!-- 무기한 이벤트 -->
                <v-sheet outlined class="pa-1 d-flex rounded-10 align-center justify-center" width="578" v-if="this.product.status=='무기한'">
                    <p class="ma-0 grey--text text--darken-2">해당 상품은 무기한 이벤트로 설정되었습니다</p>
                </v-sheet>
            </v-sheet>

            <!-- 카테고리 & 이벤트 기간 (Mobile) -->
            <v-sheet class="mt-2" v-if="$vuetify.breakpoint.mobile">
                <v-select
                    class="rounded-10"
                    height="48"
                    hide-details
                    dense
                    outlined
                    placeholder="상품 카테고리"
                    :items="select_list.category"
                    v-model="product.category"
                >
                    <template v-slot:append>
                        <v-icon class="pt-2px mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- 기간 있는 이벤트 -->
                <v-sheet outlined class="pa-1 rounded-10 mt-2" v-if="this.product.status=='진행중'">
                    <v-sheet>
                        <v-text-field
                            hide-details dense solo flat
                            type="datetime-local"
                            class="text-caption"
                            v-model="product.start_date"
                            @change="start_date()"
                        ></v-text-field>
                        <p class="my-0 mx-1 font-weight-bold text-center">~</p>
                        <v-text-field
                            hide-details dense solo flat
                            type="datetime-local"
                            class="text-caption"
                            v-model="product.end_date"
                            @change="end_date()"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>
                
                <!-- 무기한 이벤트 -->
                <v-sheet outlined class="pa-1 mt-2 rounded-10 py-2 d-flex align-center justify-center" v-if="this.product.status=='무기한'">
                    <p class="ma-0 grey--text text--darken-2">해당 상품은 무기한 이벤트로 설정되었습니다</p>
                </v-sheet>
            </v-sheet>

            <!-- 이벤트 주체 & 상품 개수 (PC) -->
            <v-sheet class="d-flex mt-2" v-if="!$vuetify.breakpoint.mobile">
                <v-select
                    class="rounded-10 mr-2"
                    style="width:211px;"
                    height="48"
                    hide-details
                    dense
                    outlined
                    placeholder="이벤트 주체"
                    :items="select_list.event_type"
                    v-model="product.event_type"
                >
                    <template v-slot:append>
                        <v-icon class="pt-2px mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <v-spacer></v-spacer>
                <v-sheet outlined class="pa-1 mr-2 rounded-10">
                    <v-sheet class="d-flex align-center justify-center" width="275">
                        <v-checkbox
                            class="mr-2"
                            dense
                            hide-details
                            label="기간없는 이벤트"
                            v-model="checkbox_for_status"
                        ></v-checkbox>
                    </v-sheet>
                </v-sheet>
                <v-sheet outlined class="pa-1 rounded-10">
                    <v-sheet class="d-flex align-center" width="275">
                        <v-sheet width="120" class="ml-4 grey--text">상품 개수</v-sheet>
                        <v-text-field
                            hide-details dense solo flat
                            placeholder="상품 개수"
                            suffix="개"
                            v-model="product.amount"
                            @input="e => product.amount = $inputToComma(e)"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>
            </v-sheet>

            <!-- 이벤트 주체 & 상품 개수 (Mobile) -->
            <v-sheet class="mt-2" v-if="$vuetify.breakpoint.mobile">
                <v-select
                    class="rounded-10"
                    height="48"
                    hide-details
                    dense
                    outlined
                    placeholder="이벤트 주체"
                    :items="select_list.event_type"
                    v-model="product.event_type"
                >
                    <template v-slot:append>
                        <v-icon class="pt-2px mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <v-sheet outlined class="pa-1 mt-2 rounded-10">
                    <v-sheet class="d-flex align-center justify-center">
                        <v-checkbox
                            dense
                            hide-details
                            label="기간없는 이벤트"
                            class="pb-1 pr-2"
                            v-model="checkbox_for_status"
                        ></v-checkbox>
                    </v-sheet>
                </v-sheet>

                <v-sheet outlined class="pa-1 mt-2 rounded-10">
                    <v-sheet class="d-flex align-center">
                        <v-sheet width="120" class="ml-4 grey--text">상품 개수</v-sheet>
                        <v-text-field
                            hide-details dense solo flat
                            placeholder="상품 개수"
                            preffix="개"
                            reverse
                            v-model="product.amount"
                            @input="e => product.amount = $inputToComma(e)"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>
            </v-sheet>

            <!-- 하단 버튼 -->
            <v-sheet class="mt-3 d-flex justify-center">
                <v-btn
                    height="49"
                    width="140"
                    class="mx-2 font-weight-bold rounded-10"
                    style="font-size:16px;"
                    color="primary"
                    dark
                    depressed
                    @click="submit()"
                >
                    등록하기
                </v-btn>
                <v-btn
                    height="49"
                    width="140"
                    class="mx-2 font-weight-bold rounded-10"
                    style="font-size:16px;"
                    color="#ADAFCA"
                    dark
                    depressed
                    @click="$router.go(-1)"
                >
                    취소하기
                </v-btn>
            </v-sheet>
        </v-card>
    </v-sheet>
</template>
<script>
import PointHeader from './components/header'
import Thumbnail from "./components/thumbnail"
import TipTapWriter from "@/components/tiptap/Writer"
import TipTapWriterMobile from "@/components/tiptap/WriterMobile"

export default {
    components: {
        PointHeader,
        Thumbnail,
        TipTapWriter,
        TipTapWriterMobile
    },

    data: () => ({
        // 상품
        product: {
            thumbnail: '',
            title: '',
            sell_type: '',
            price: 0,
            delivery_price: 0,
            category: '',
            start_date: new Date().toISOString().slice(0, -5),
            end_date: new Date().toISOString().slice(0, -5),
            event_type: '',
            amount: 0,
            status: '진행중'
        },

        // dialog
        dialog: {
            thumbnail: false
        },

        // 선택목록
        select_list: {
            category: ['신제품', '중고품'],
            sell_type: ['당첨형', '선착순'],
            event_type: ['이벤트', '파트너', 'AD'],
        },

        //TipTap
        options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true,
        },

        checkbox_for_status: false
    }),

    watch: {
        checkbox_for_status(){
            if(this.checkbox_for_status){
                this.product.status = '무기한'
            }else{
                this.product.status = '진행중'
            }
        },
    },


    mounted(){
        if(this.$store.state.type!='관리자'){
            alert('관리자 외에는 들어올 수 없는 페이지입니다.');
        }
    },

    methods: {
        start_date(){
            if(new Date(this.product.start_date).getTime() > new Date(this.product.end_date)){
                alert('이벤트 시작일은 이벤트 종료일 이후가 될 수 없습니다. \n입력값 대신 종료일과 동일한 날짜로 변경되었습니다.')
                this.product.start_date = this.product.end_date
            }
        },

        end_date(){
            if(new Date(this.product.end_date).getTime() < new Date(this.product.start_date)){
                alert('이벤트 종료일은 이벤트 시작일 이전이 될 수 없습니다. \n입력값 대신 시작일과 동일한 날짜로 변경되었습니다.')
                this.product.end_date = this.product.start_date
            }
        },

        // 내용 Count 값 import
        import_content_count(count){
            this.content_count = count
        },

        imageImport(thumbnail){
            this.product.thumbnail = thumbnail
        },

        submit(){
            if(typeof(this.product.thumbnail)=="string")
            {
                alert('상품 썸네일을 입력해주세요')
            }
            else if(this.product.title=='')
            {
                alert('상품명을 입력해주세요')
            }
            else if(this.options.content=='')
            {
                alert('상품 설명을 입력해주세요')
            }
            else if(this.product.sell_type=='')
            {
                alert('상품 추첨방식을 선택해주세요')
            }
            else if(this.product.category=='')
            {
                alert('상품 카테고리를 선택해주세요')
            }
            else if(this.product.event_type=='')
            {
                alert('이벤트 주체를 선택해주세요')
            }
            else if(this.product.amount==0)
            {
                alert('상품 갯수는 0개가 될 수 없습니다')
            }
            else{
                this.product.price = this.$toUnComma(this.product.price)
                this.product.delivery_price = this.$toUnComma(this.product.delivery_price)
                this.product.amount = this.$toUnComma(this.product.amount)

                var file = this.product.thumbnail
                var formData = new FormData()
                formData.append("image", file)
                this.$http.post('/api/image/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    params: {
                        type: "pointmall/thumbnail"
                    }
                }).then((res) => {
                     this.$http.post('/api/pointmall/insert', {
                        params: {
                            thumbnail : res.data,
                            title : this.product.title,
                            content : this.options.content,
                            sell_type : this.product.sell_type,
                            price : this.product.price,
                            delivery_price : this.product.delivery_price,
                            category : this.product.category,
                            start_date : this.product.start_date,
                            end_date : this.product.end_date,
                            event_type : this.product.event_type,
                            amount : this.product.amount,
                            status : this.product.status
                        }
                    }).then(() => {
                        this.$router.push('/pointmall/list')
                    })
                }).catch((err) => {
                    console.log('FAILURE!!'+ err)
                })
            }
        }
    }
}
</script>
<style scoped>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}
</style>