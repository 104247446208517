<template>
    <div>
        <!-- PC -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
            class="d-flex justify-space-between mt-2"
        >
            <!-- 이벤트 -->
            <v-sheet
                v-for="item in list"
                :key="item.id"
                @click="goToRead(item)"
                outlined
                width="195"
                height="90"
                class="rounded-15 pa-2"
                style="cursor:pointer;"
            >
                <div class="d-flex justify-space-between align-center mr-1">
                    <!-- 상태 -->
                    <div>
                        <v-sheet v-if="new Date(item.start_date) < new Date()" color="blue" width="82" height="32" class="d-flex align-center justify-center rounded-15">
                            <p style="font-size:13px; letter-spacing:-0.4px;" class="font-weight-bold white--text ma-0 pt-2px">{{item.status}}</p>
                        </v-sheet>
                        <v-sheet v-if="new Date(item.start_date) > new Date()" color="primary" width="82" height="32" class="d-flex align-center justify-center rounded-15">
                            <p style="font-size:13px; letter-spacing:-0.4px;" class="font-weight-bold white--text ma-0 pt-2px text-center">다음 이벤트</p>
                        </v-sheet>
                    </div>

                    <!-- 가격 -->
                    <span style="font-size:14px; color:#F8468D; font-weight:bold;">{{$toComma(item.price)}}P</span>
                </div>

                <!-- 상품명 -->
                <p class="ml-1 mb-1 mt-6px text-truncate font-weight-bold" style="font-size:10px; width:170px;">{{item.title}}</p>

                <!-- 기간 -->
                <p style="font-size:10px; width:170px; line-height:11px;" class="text-end">
                    {{new Date(item.start_date).toLocaleDateString()}}~ {{new Date(item.end_date).toLocaleDateString()}}
                </p>
            </v-sheet>

            <!-- 진행중인 이벤트가 부족할때 -->
            <v-sheet
                v-for="n in 4-list.length"
                :key="n"
                outlined
                width="195"
                height="90"
                class="d-flex rounded-15 pa-1"
            >
                <v-sheet class="d-flex align-center justify-center" width="100%">
                    <span style="font-size:12px; font-weight:bold;">다음 이벤트를 기대해 주세요</span>
                </v-sheet>
            </v-sheet>
        </v-sheet>

        <!-- Mobile -->
        <v-sheet
            v-if="$vuetify.breakpoint.mobile"
            class="d-flex justify-space-between mt-2"
            width="100%"
            color="transparent"
        >
            <!-- 이벤트 -->
            <v-sheet
                v-for="item in first_list"
                :key="item.id"
                @click="goToRead(item)"
                outlined
                width="49%"
                height="90"
                class="rounded-10 pa-2"
                style="cursor:pointer; border:1px solid #E0E0EB;"
            >
                <div class="d-flex justify-space-between align-center mr-1">
                    <!-- 상태 -->
                    <div>
                        <v-sheet v-if="new Date(item.start_date) < new Date()" color="blue" width="52" height="24" class="d-flex align-center justify-center rounded-15">
                            <p style="font-size:12px; letter-spacing:-0.4px;" class="white--text ma-0 pt-2px">{{item.status}}</p>
                        </v-sheet>
                        <v-sheet v-if="new Date(item.start_date) > new Date()" color="primary" width="52" height="24" class="d-flex align-center justify-center rounded-15">
                            <p style="font-size:10px; letter-spacing:-0.8px;" class="white--text ma-0 pt-2px text-center">다음이벤트</p>
                        </v-sheet>
                    </div>

                    <!-- 가격 -->
                    <span style="font-size:12px; color:#F8468D; font-weight:bold;">{{$toComma(item.price)}}P</span>
                </div>

                <!-- 상품명 -->
                <p
                    class="ml-1 mb-1 mt-6px text-truncate font-weight-bold"
                    style="font-size:9px;"
                >
                    {{item.title}}
                </p>

                <!-- 기간 -->
                <p style="font-size:9px; line-height:11px;" class="text-end">
                    {{new Date(item.start_date).toLocaleDateString()}}~ {{new Date(item.end_date).toLocaleDateString()}}
                </p>
            </v-sheet>

            <!-- 진행중인 이벤트가 부족할때 -->
            <v-sheet
                v-for="n in 2-first_list.length"
                :key="n"
                outlined
                width="49%"
                height="90"
                class="d-flex rounded-10 pa-1"
            >
                <v-sheet class="d-flex align-center justify-center" width="100%">
                    <span style="font-size:12px; font-weight:bold;">다음 이벤트를 기대해 주세요</span>
                </v-sheet>
            </v-sheet>
        </v-sheet>

        <v-sheet
            v-if="$vuetify.breakpoint.mobile"
            class="d-flex justify-space-between mt-2"
            color="transparent"
        >
            <!-- 이벤트 -->
            <v-sheet
                v-for="item in second_list"
                :key="item.id"
                @click="goToRead(item)"
                outlined
                width="49%"
                height="90"
                class="rounded-10 pa-2"
                style="cursor:pointer; border:1px solid #E0E0EB;"
            >
                <div class="d-flex justify-space-between align-center mr-1">
                    <!-- 상태 -->
                    <div>
                        <v-sheet v-if="new Date(item.start_date) < new Date()" color="blue" width="52" height="24" class="d-flex align-center justify-center rounded-15">
                            <p style="font-size:12px; letter-spacing:-0.4px;" class="white--text ma-0 pt-2px">{{item.status}}</p>
                        </v-sheet>
                        <v-sheet v-if="new Date(item.start_date) > new Date()" color="primary" width="52" height="24" class="d-flex align-center justify-center rounded-15">
                            <p style="font-size:10px; letter-spacing:-0.8px;" class="white--text ma-0 pt-2px text-center">다음이벤트</p>
                        </v-sheet>
                    </div>

                    <!-- 가격 -->
                    <span style="font-size:12px; color:#F8468D; font-weight:bold;">{{$toComma(item.price)}}P</span>
                </div>

                <!-- 상품명 -->
                <p
                    class="ml-1 mb-1 mt-6px text-truncate font-weight-bold"
                    style="font-size:9px;"
                >
                    {{item.title}}
                </p>

                <!-- 기간 -->
                <p style="font-size:9px; line-height:11px;" class="text-end">
                    {{new Date(item.start_date).toLocaleDateString()}}~ {{new Date(item.end_date).toLocaleDateString()}}
                </p>
            </v-sheet>

            <!-- 진행중인 이벤트가 부족할때 -->
            <v-sheet
                v-for="n in 2-second_list.length"
                :key="n"
                outlined
                width="49%"
                height="90"
                class="d-flex rounded-10 pa-1"
            >
                <v-sheet class="d-flex align-center justify-center" width="100%">
                    <span style="font-size:12px; font-weight:bold;">다음 이벤트를 기대해 주세요</span>
                </v-sheet>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
export default {
    data: () => ({
        list: [],
        first_list: [],
        second_list: []
    }),

    mounted() {
        this.$http.post('/api/pointmall/select/timeline')
        .then((res) => {
            this.list = res.data.slice(0,4)
            this.first_list = res.data.slice(0,2)
            this.second_list = res.data.slice(2,4)
        })
    },

    methods: {
        // 상품상세 페이지로 이동
        goToRead(item){
            this.$router.push('/pointmall/read?id='+item.id)
        }
    }
}
</script>