<template>
    <v-sheet color="transparent">
        <!-- 헤더 -->
        <PointHeader />
        <!-- 상품 등록 -->
        <v-card class="pa-4 rounded-10 mt-2" flat>
            <!-- 썸네일 & 제목 -->
            <v-sheet class="d-flex">
                <v-sheet>
                    <v-btn
                        height="48"
                        max-width="210"
                        dark
                        color="blue lighten-1"
                        class="rounded-10"
                        outlined
                        @click="dialog.thumbnail=true"
                    >
                        상품 썸네일 등록하기
                    </v-btn>
                    <v-dialog v-model="dialog.thumbnail" width="auto">
                        <Thumbnail 
                            @imageUpdated="imageImport"
                            @close="dialog.thumbnail = false"
                        />
                    </v-dialog>
                </v-sheet>
                <v-text-field
                    height="48"
                    class="rounded-10 ml-2"
                    hide-details
                    dense
                    outlined
                    placeholder="상품명을 입력해주세요"
                    solo
                    flat
                    v-model="product.title"
                ></v-text-field>
            </v-sheet>

            <!-- 본문 (상품 설명) -->
            <v-sheet class="mt-2">
                <TipTapWriterMobile
                    v-if="$vuetify.breakpoint.mobile && options.content"
                    class="tiptap_style"
                    :options="options"
                    @emit_count="import_content_count"
                />
                <TipTapWriter
                    v-if="!$vuetify.breakpoint.mobile && options.content"
                    class="tiptap_style"
                    :options="options"
                    @emit_count="import_content_count"
                />
            </v-sheet>

            <!-- 추첨방식 & 가격 & 배송비 (PC) -->
            <v-sheet
                v-if="!$vuetify.breakpoint.mobile"
                class="d-flex mt-2"
            >
                <v-select
                    class="rounded-10 mr-2"
                    style="width:211px;"
                    height="48"
                    hide-details
                    dense
                    outlined
                    placeholder="상품 추첨방식"
                    :items="select_list.sell_type"
                    v-model="product.sell_type"
                >
                    <template v-slot:append>
                        <v-icon class="pt-2px mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <v-spacer></v-spacer>
                <v-sheet outlined class="pa-1 mr-2 rounded-10">
                    <v-sheet class="d-flex align-center" width="275">
                        <v-sheet width="120" class="text-center grey--text mr-4">상품가격</v-sheet>
                        <v-text-field
                            hide-details dense solo flat
                            placeholder="상품 가격"
                            suffix="P"
                            v-model="product.price"
                            @input="e => product.price = $inputToComma(e)"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>
                <v-sheet outlined class="pa-1 rounded-10">
                    <v-sheet class="d-flex align-center" width="275">
                        <v-sheet width="120" class="text-center grey--text mr-4">배송비</v-sheet>
                        <v-text-field
                            hide-details dense solo flat
                            placeholder="배송비"
                            suffix="P"
                            v-model="product.delivery_price"
                            @input="e => product.delivery_price = $inputToComma(e)"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>
            </v-sheet>

            <!-- 추첨방식 & 가격 & 배송비 (Mobile) -->
            <v-sheet
                v-if="$vuetify.breakpoint.mobile"
                class="mt-2"
            >
                <v-select
                    class="rounded-10"
                    height="48"
                    hide-details
                    dense
                    outlined
                    placeholder="상품 추첨방식"
                    :items="select_list.sell_type"
                    v-model="product.sell_type"
                >
                    <template v-slot:append>
                        <v-icon class="pt-2px mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>
                
                <v-sheet outlined class="pa-1 rounded-10 mt-2">
                    <v-sheet class="d-flex align-center">
                        <v-sheet width="120" class="grey--text ml-4">상품가격</v-sheet>
                        <v-text-field
                            hide-details dense solo flat
                            placeholder="상품 가격"
                            reverse
                            prefix="P"
                            v-model="product.price"
                            @input="e => product.price = $inputToComma(e)"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>

                <v-sheet outlined class="pa-1 rounded-10 mt-2">
                    <v-sheet class="d-flex align-center">
                        <v-sheet width="120" class="grey--text ml-4">배송비</v-sheet>
                        <v-text-field
                            hide-details dense solo flat
                            placeholder="배송비"
                            reverse
                            prefix="P"
                            v-model="product.delivery_price"
                            @input="e => product.delivery_price = $inputToComma(e)"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>
            </v-sheet>

            <!-- 카테고리 & 이벤트 기간 (PC) -->
            <v-sheet class="d-flex mt-2" v-if="!$vuetify.breakpoint.mobile">
                <v-select
                    class="rounded-10 mr-2"
                    style="width:211px;"
                    height="48"
                    hide-details
                    dense
                    outlined
                    placeholder="상품 카테고리"
                    :items="select_list.category"
                    v-model="product.category"
                >
                    <template v-slot:append>
                        <v-icon class="pt-2px mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <v-spacer></v-spacer>
                <!-- 기간 있는 이벤트 -->
                <v-sheet outlined class="pa-1 rounded-10" v-if="this.product.status=='진행중'">
                    <v-sheet class="d-flex align-center" width="568">
                        <v-text-field
                            hide-details dense solo flat
                            type="datetime-local"
                            class="text-body-2"
                            v-model="product.start_date"
                            @change="start_date()"
                        ></v-text-field>
                        <p class="my-0 mx-2 font-weight-bold">~</p>
                        <v-text-field
                            hide-details dense solo flat
                            type="datetime-local"
                            class="text-body-2"
                            v-model="product.end_date"
                            @change="end_date()"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>
                <!-- 무기한 이벤트 -->
                <v-sheet outlined class="pa-1 d-flex rounded-10 align-center justify-center" width="578" v-if="this.product.status=='무기한'">
                    <p class="ma-0 grey--text text--darken-2">해당 상품은 무기한 이벤트로 설정되었습니다</p>
                </v-sheet>
            </v-sheet>

            <!-- 카테고리 & 이벤트 기간 (Mobile) -->
            <v-sheet class="mt-2" v-if="$vuetify.breakpoint.mobile">
                <v-select
                    class="rounded-10"
                    height="48"
                    hide-details
                    dense
                    outlined
                    placeholder="상품 카테고리"
                    :items="select_list.category"
                    v-model="product.category"
                >
                    <template v-slot:append>
                        <v-icon class="pt-2px mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- 기간 있는 이벤트 -->
                <v-sheet outlined class="pa-1 rounded-10 mt-2" v-if="this.product.status=='진행중'">
                    <v-sheet>
                        <v-text-field
                            hide-details dense solo flat
                            type="datetime-local"
                            class="text-caption"
                            v-model="product.start_date"
                            @change="start_date()"
                        ></v-text-field>
                        <p class="my-0 mx-1 font-weight-bold text-center">~</p>
                        <v-text-field
                            hide-details dense solo flat
                            type="datetime-local"
                            class="text-caption"
                            v-model="product.end_date"
                            @change="end_date()"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>
                <!-- 무기한 이벤트 -->
                <v-sheet outlined class="pa-1 mt-2 rounded-10 py-2 d-flex align-center justify-center" v-if="this.product.status=='무기한'">
                    <p class="ma-0 grey--text text--darken-2">해당 상품은 무기한 이벤트로 설정되었습니다</p>
                </v-sheet>
            </v-sheet>

            <!-- 이벤트 주체 & 상품 개수 (PC) -->
            <v-sheet class="d-flex mt-2" v-if="!$vuetify.breakpoint.mobile">
                <v-select
                    class="rounded-10 mr-2"
                    style="width:211px;"
                    height="48"
                    hide-details
                    dense
                    outlined
                    placeholder="이벤트 주체"
                    :items="select_list.event_type"
                    v-model="product.event_type"
                >
                    <template v-slot:append>
                        <v-icon class="pt-2px mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <v-spacer></v-spacer>
                <v-sheet outlined class="pa-1 mr-2 rounded-10">
                    <v-sheet class="d-flex align-center justify-center" width="275">
                        <v-checkbox
                            class="mr-2"
                            dense
                            hide-details
                            label="기간없는 이벤트"
                            v-model="checkbox_for_status"
                        ></v-checkbox>
                    </v-sheet>
                </v-sheet>
                <v-sheet outlined class="pa-1 rounded-10">
                    <v-sheet class="d-flex align-center" width="275">
                        <v-sheet width="120" class="ml-4 grey--text">상품 개수</v-sheet>
                        <v-text-field
                            hide-details dense solo flat
                            placeholder="상품 개수"
                            suffix="개"
                            v-model="product.amount"
                            @input="e => product.amount = $inputToComma(e)"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>
            </v-sheet>

            <!-- 이벤트 주체 & 상품 개수 (Mobile) -->
            <v-sheet class="mt-2" v-if="$vuetify.breakpoint.mobile">
                <v-select
                    class="rounded-10"
                    height="48"
                    hide-details
                    dense
                    outlined
                    placeholder="이벤트 주체"
                    :items="select_list.event_type"
                    v-model="product.event_type"
                >
                    <template v-slot:append>
                        <v-icon class="pt-2px mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <v-sheet outlined class="pa-1 mt-2 rounded-10">
                    <v-sheet class="d-flex align-center justify-center">
                        <v-checkbox
                            dense
                            hide-details
                            label="기간없는 이벤트"
                            class="pb-1 pr-2"
                            v-model="checkbox_for_status"
                        ></v-checkbox>
                    </v-sheet>
                </v-sheet>

                <v-sheet outlined class="pa-1 mt-2 rounded-10">
                    <v-sheet class="d-flex align-center">
                        <v-sheet width="120" class="ml-4 grey--text">상품 개수</v-sheet>
                        <v-text-field
                            hide-details dense solo flat
                            placeholder="상품 개수"
                            preffix="개"
                            reverse
                            v-model="product.amount"
                            @input="e => product.amount = $inputToComma(e)"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>
            </v-sheet>

            <!-- 하단 버튼 -->
            <v-sheet class="mt-3 d-flex justify-center">
                <v-btn
                    height="49"
                    width="140"
                    class="mx-2 font-weight-bold rounded-10"
                    style="font-size:16px;"
                    color="primary"
                    dark
                    depressed
                    @click="submit()"
                >
                    등록하기
                </v-btn>
                <v-btn
                    height="49"
                    width="140"
                    class="mx-2 font-weight-bold rounded-10"
                    style="font-size:16px;"
                    color="#ADAFCA"
                    dark
                    depressed
                    @click="$router.go(-1)"
                >
                    취소하기
                </v-btn>
            </v-sheet>
        </v-card>
    </v-sheet>
</template>
<script>
import PointHeader from './components/header'
import Thumbnail from "./components/thumbnail"
import TipTapWriter from "@/components/tiptap/Writer"
import TipTapWriterMobile from "@/components/tiptap/WriterMobile"

export default {
    components: {
        PointHeader,
        Thumbnail,
        TipTapWriter,
        TipTapWriterMobile
    },

    data: () => ({
        // 상품
        product: {
            thumbnail: '',
            title: '',
            sell_type: '',
            price: 0,
            delivery_price: 0,
            category: '',
            start_date: '',
            end_date: '',
            event_type: '',
            amount: 0,
            status: ''
        },

        // dialog
        dialog: {
            thumbnail: false
        },

        // 선택목록
        select_list: {
            category: ['신제품', '중고품'],
            sell_type: ['당첨형', '선착순'],
            event_type: ['이벤트', '파트너', 'AD'],
        },

        //TipTap
        options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true,
        },

        checkbox_for_status: false,

        isThumbnailUpdated: false
    }),


    mounted(){
        if(this.$store.state.type!='관리자'){
            alert('관리자 외에는 들어올 수 없는 페이지입니다.');
        }

        // 상품 정보 불러오기
        this.$http.post('/api/pointmall/select/read', {
            params: {
                id: this.$route.query.id
            }
        }).then((res) => {
            this.product = res.data[0]
            this.product.price = this.$toComma(res.data[0].price)
            this.product.delivery_price = this.$toComma(res.data[0].delivery_price)
            this.product.amount = this.$toComma(res.data[0].amount)

            this.product.start_date = new Date(res.data[0].start_date).toISOString().slice(0, -5)
            this.product.end_date = new Date(res.data[0].end_date).toISOString().slice(0, -5)
            this.options.content = res.data[0].content

            if(res.data[0].status=='무기한'){
                this.checkbox_for_status = true
            }
        })
    },

    watch: {
        checkbox_for_status(){
            if(this.checkbox_for_status){
                this.product.status = '무기한'
            }else{
                this.product.status = '진행중'
            }
        },
    },

    methods: {
        // 내용 Count 값 import
        import_content_count(count){
            this.content_count = count
        },

        imageImport(thumbnail){
            console.log(thumbnail)
            this.isThumbnailUpdated = true
            this.product.thumbnail = thumbnail
        },

        submit(){
            this.product.price = this.$toUnComma(this.product.price)
            this.product.delivery_price = this.$toUnComma(this.product.delivery_price)
            this.product.amount = this.$toUnComma(this.product.amount)


            if(this.isThumbnailUpdated){
                var file = this.product.thumbnail
                var formData = new FormData()
                formData.append("image", file)
                this.$http.post('/api/image/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    params: {
                        type: "pointmall/thumbnail"
                    }
                }).then((res) => {
                    this.product.thumbnail = res.data

                    this.$http.post('/api/pointmall/update/submit', {
                        params: {
                            product: this.product,
                            content: this.options.content,
                            id: this.$route.query.id
                        }
                    }).then((res) => {
                        alert('해당 상품이 수정되었습니다.')
                        this.$router.go(-1);
                    })
                }).catch((err) => {
                    console.log('FAILURE!!'+ err)
                })
            }else{
                this.$http.post('/api/pointmall/update/submit', {
                    params: {
                        product: this.product,
                        content: this.options.content,
                        id: this.$route.query.id
                    }
                }).then((res) => {
                    alert('해당 상품이 수정되었습니다.')
                    this.$router.go(-1);
                })
            }
        }
    }
}
</script>
<style scoped>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}
</style>