<template>
    <div>
        <!-- 헤더 -->
        <PointHeader />

        <!-- 목록 -->
        <ProductList />
    </div>
</template>
<script>
import PointHeader from './components/header'
import ProductList from './components/list'

export default {
    components: {
        PointHeader,
        ProductList
    }
}
</script>