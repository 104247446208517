<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <div class="pa-4 pr-0 d-flex align-center">
                <!-- 제목 -->
                <div
                    style="cursor:pointer;" 
                    @click="$router.push('/pointmall/list')"
                >
                    <p style="font-size:26px; font-weight:bold; margin-bottom:4px;">포인트몰</p>
                    <p style="font-size:11px;" class="mb-0">사이트 활동으로 얻은 포인트로 포켓몬카드를 구매!</p>
                </div>
                <v-spacer></v-spacer>

                <!-- 메뉴 -->
                <v-sheet class="px-7 rounded-15 d-flex justify-space-between">
                    <!-- 포인트 획득 가이드 -->
                    <v-btn
                        text
                        height="56"
                        class="px-0"
                        v-ripple="false"
                        style="font-size:18px; font-weight:bold; letter-spacing:-0.5px; min-width:0px;"
                        @click="$router.push('/policy/pointguide?type=read')"
                    >
                        포인트 획득 가이드
                    </v-btn>
                    <v-divider vertical class="mx-6 my-4" color="#B3B3C2"></v-divider>

                    <!-- 당첨/구매 공지 -->
                    <v-btn
                        depressed
                        text
                        tile
                        height="56"
                        class="px-0"
                        v-ripple="false"
                        style="font-size:18px; font-weight:bold; letter-spacing:-0.5px; min-width:0px;"
                        @click="$router.push('/board/list?board_group=포인트몰&board_name=당첨%20%2F%20구매%20공지게시판')"
                    >
                        당첨/구매 공지
                    </v-btn>
                    <v-divider vertical class="mx-6 my-4" color="#B3B3C2"></v-divider>

                    <!-- 상품 협찬하기 -->
                    <v-btn
                        text
                        height="56"
                        class="px-0"
                        v-ripple="false"
                        style="font-size:18px; font-weight:bold; letter-spacing:-0.5px; min-width:0px;"
                        @click="$router.push('/board/list?board_group=포인트몰&board_name=상품%20협찬하기')"
                    >
                        상품 협찬하기
                    </v-btn>
                </v-sheet>
            </div>
            <v-card class="pa-4 rounded-15" flat>
                <v-sheet class="d-flex justify-space-between">
                    <BannerView type="pointmall_event1" width="395" height="140" :round="'rounded-10'" />
                    <BannerView type="pointmall_event2" width="395" height="140" :round="'rounded-10'" />
                </v-sheet>
                <TimeLine v-if="$route.path.startsWith('/pointmall/list')"/>
            </v-card>
        </div>
        
        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
            class="px-4 pt-3"
        >
            <div class="pr-0 d-flex justify-space-between align-center">
                <!-- 제목 -->
                <div
                    style="cursor:pointer;" 
                    @click="$router.push('/pointmall/list')"
                >
                    <p style="font-size:16px; font-weight:bold; margin-bottom:4px;">포인트몰</p>
                </div>

                <!-- 당첨/구매 공지 -->
                <v-btn
                    depressed
                    dark
                    width="112"
                    height="28"
                    class="rounded-10 px-0"
                    color="primary"
                    v-ripple="false"
                    style="font-size:12px;"
                    @click="$router.push('/board/list?board_group=포인트몰&board_name=당첨%20%2F%20구매%20공지게시판')"
                >
                    당첨/구매 공지
                </v-btn>
            </div>

            <v-sheet class="d-flex justify-space-between mt-2 rounded-10">
                <v-carousel
                    class="rounded-10"
                    height="35.4vw"
                    :hide-delimiter-background="true"
                    :cycle="true"
                    interval="5000" 
                    show-arrows-on-hover
                >
                    <v-carousel-item>
                        <BannerView type="pointmall_event1" width="100%" :round="'rounded-10'" />
                    </v-carousel-item>
                    <v-carousel-item>
                        <BannerView type="pointmall_event2" width="100%" :round="'rounded-10'" />
                    </v-carousel-item>
                </v-carousel>
            </v-sheet>

            <TimeLine
                v-if="$route.path.startsWith('/pointmall/list')"
            />
        </div>
    </div>
</template>
<script>
import BannerView from '@/components/BannerView'
import TimeLine from './header/timeline.vue'

export default {
    components: {
        BannerView,
        TimeLine
    }
}
</script>
<style scoped>
.v-btn::before {
  background-color: transparent;
}
</style>